import React from "react";
import {
    Box,
} from "@chakra-ui/react";
import { RichText } from 'prismic-reactjs';
import { CustomLink } from '../../utils/customLink'

const RichTextSlice = ({ slice }) => {
    console.log(slice.primary.content.richText)
    return (
        <Box my="8">
            <RichText render={slice.primary.content.richText} serializeHyperlink={CustomLink} />
        </Box>
    );
}

export default RichTextSlice;