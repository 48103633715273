import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Flex,
  Box,
  Heading
} from '@chakra-ui/react'
import { RichText } from 'prismic-reactjs'
import PageHero from '../components/PageHero/PageHero'
import MobilePageHero from '../components/PageHero/MobilePageHero'
import { getImage } from 'gatsby-plugin-image'
import Tetons from '../components/PageHero/Tetons'
import ParallaxTitle from '../components/PageHero/ParallaxTitle'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Sidebar from '../components/Sidebar/Sidebar'
import MooseLottie from '../components/Footer/Moose/MooseLottie'
import { CustomLink } from '../utils/customLink'
import SliceZone from '../components/SliceZone/SliceZone'

const PageTemplate = (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data } = props;
      const pageLocation = location.pathname

  if (!data) return null; 

  const document = data.prismicPage.data
  const backgroundImage = getImage(document.hero_image.localFile)
  const image = getImage(backgroundImage);

  const menuItems = null || document.sidebar_menu.document?.data.body


  return (
    <Layout>
      <Seo title={document.page_title.text}  />
      <PageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
        pageTitle={document.page_headline?.text}
      >
        <Flex w="100%" height="60vh" flexDirection="column" justifyContent="flex-end" paddingBottom="5%" alignItems="left">
          <ParallaxTitle>
            <Box px="4" zIndex="1">
              <Heading color="white" as="h1" fontSize="4rem" textTransform="uppercase">
                {document.page_headline?.text}
              </Heading>
            </Box>
          </ParallaxTitle>
        </Flex>
        <Tetons />
      </PageHero>
     <MobilePageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
        pageTitle={document.page_headline?.text}
      />
      <Box position="relative" zIndex="1">
      <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
            <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text}  
                menuItems={menuItems}
                pageLocation={pageLocation}
              />
            </Box>
            <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {document.page_title.text}
            </Heading>
            <Heading as="h3" fontSize="2rem" variant="page-subtitle">
              {document.subtitle.text}
            </Heading>

        <RichText render={document.page_content.richText} serializeHyperlink={CustomLink}/>
        
        <SliceZone sliceZone={document.body}/>

      </Box>
      <MooseLottie />
        </Flex>
      </Box>
      
    </Layout>
  )
}



    export const query = graphql`
    query PageQuery($uid: String!) {
      prismicPage(uid: {eq: $uid}) {
        _previewable
        uid
        type
        data {
          page_headline {
            text
          }
          page_title {
            text
          }
          hero_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          page_content {
            richText
          }
          subtitle {
            text
          }
          sidebar_menu {
            document {
              ... on PrismicSidebarNavigation {
                id
                data {
                  body {
                    ... on PrismicSidebarNavigationDataBodySidebarNavAccordionItem {
                      id
                      slice_type
                      primary {
                        navigation_group_title {
                          text
                        }
                        title_link {
                          url
                          uid
                        }
                      }
                      items {
                        link {
                          url
                          uid
                        }
                        item_title {
                          text
                        }
                      }
                    }
                  }
                  menu_title {
                    text
                  }
                }
              }
            }
          }
          body {
            ... on PrismicPageDataBodyPhotoGallery {
              id
              slice_type
              items {
                image {
                  localFile {
                    childImageSharp {
                      full: gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                      thumb: gatsbyImageData(
                        placeholder: BLURRED
                        aspectRatio: 1
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                  alt
                }
              }
            }
            ... on PrismicPageDataBodyBidRfpProjectAccordion {
              id
              slice_type
              primary {
                accordion_title {
                  text
                }
                accordion_subtitle {
                  text
                }
              }
              items {
                item_title {
                  text
                }
                item_description {
                  text
                  richText
                }
                accordion_item_id {
                  text
                }
              }
            }
            ... on PrismicPageDataBodyContactCard {
              id
              slice_type
              primary {
                department_contact_information {
                  richText
                  text
                }
              }
              items {
                contact_title {
                  text
                }
                contact_phone {
                  text
                }
                contact_name {
                  text
                }
                contact_extension
                contact_email {
                  text
                }
              }
            }
            ... on PrismicPageDataBodyFormLinkModule {
              id
              slice_type
              primary {
                form_links_title {
                  text
                }
              }
              items {
                form_links {
                  url
                  uid
                  type
                  target
                  id
                  document {
                    ... on PrismicFormLinkModule {
                      id
                      data {
                        link_to_pdf {
                          url
                        }
                        form_title {
                          text
                        }
                        form_link {
                          url
                        }
                        form_description {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyIframeSlice {
              id
              slice_type
              primary {
                iframe_source {
                  text
                }
              }
            }
            ... on PrismicPageDataBodyMeetingTabs {
              id
              slice_type
              primary {
                zoom_meeting_link {
                  text
                }
                vimeo_meeting_iframe_embed_code {
                  text
                }
                upcoming_meeting_date(formatString: "MM DD YYYY")
                upcoming_meeting_agenda_packet {
                  text
                }
                previous_meeting_date(formatString: "MM DD YYYY")
                previous_meeting_agenda_packet {
                  text
                }
                link_to_upcoming_meeting_packet_pdf {
                  url
                  uid
                  target
                }
                link_to_previous_meeting_packet_pdf {
                  url
                  uid
                  target
                }
                link_to_previous_meeting_minutes_pdf {
                  url
                  uid
                  target
                }
              }
            }
            ... on PrismicPageDataBodyRawHtmlSlice {
              id
              slice_type
              primary {
                html_content {
                  richText
                  text
                  raw
                  html
                }
              }
            }
            ... on PrismicPageDataBodyRichText {
              id
              slice_type
              primary {
                content {
                  richText
                }
              }
            }
          }
        }
      }
    }
  `

export default withPrismicPreview(PageTemplate)
