import React from "react";
import {
    Box
} from '@chakra-ui/react';

const RawHtmlSlice = ({ slice }) => {
    return (
        <Box dangerouslySetInnerHTML={{ __html: slice.primary.html_content.raw[0].text }} />
    );
}

export default RawHtmlSlice;