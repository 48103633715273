import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Link
} from '@chakra-ui/react'
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import { linkResolver } from './linkResolver'

export const CustomLink = (type, element, content, children, index) => {
  //console.log(element.data.url)
  return (
    <>
    {/* Check to see if element.data.link_type exists before rendering */}
      {element.data && element.data.link_type === 'Document' ? (
        <Link as={GatsbyLink} to={linkResolver(element?.data)} key={element.data?.id} textDecoration="underline">
          {content}
        </Link>
        ) : element.data && element.data.link_type === 'Media' ? (
          <a href={element.data?.url} target="_blank" rel="noopener noreferrer">
            <strong>{content}
            {element.data?.url.includes('.pdf' || '.PDF') && <span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>}</strong>
          </a>
        ) : element.data && element.data.link_type === 'Web' ? (
          <Link href={element.data?.url} target="_blank" rel="noopener noreferrer">
             {content}
             {element.data?.url.includes('.pdf' || '.PDF') 
              ? (<span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>) 
              : (element.data?.url.includes('municode'))
              ? (<span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>) 
              : (<span>{' '}<ExternalLinkIcon style={{display: 'inline-block', verticalAlign: 'middle', marginBottom: '0.25rem'}} /></span>)}
          </Link>
        ) : null }
        
    </>
  )
}